

















































































import { Groups, Global, User } from '@/store';

import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class GroupsListTable extends Vue {
  @Prop() selectedGroups;

  @Global.State('readonly_user') readonly_user;
  @Global.State('lang') lang;
  @Groups.State('list') groups;
  @Groups.Action('load') loadGroups;
  @User.State('project') project;

  search = '';
  loading = true;

  async mounted() {
    this.loading = true;
    await this.load();
    this.loading = false;
  }

  get items() {
    return this.groups && this.groups.length
      ? this.groups.map((group) => this.generateRowData(group))
      : [];
  }

  generateRowData(group) {
    const rowData = {
      id: group.id,
      name: group.name,
      description: group.description
    };
    return rowData;
  }

  @Watch('project')
  async load() {
    if (this.project) await this.loadGroups({ project_id: this.project.id });
  }

  get headers() {
    return [
      {
        text: 'Group ID',
        sortable: false,
        class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right'],
        value: 'id'
      },
      {
        text: 'Name',
        sortable: false,
        class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right'],
        value: 'name'
      },
      {
        text: 'Description',
        sortable: false,
        class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right'],
        value: 'description'
      }
    ];
  }

  updateSelected(selectedProp, item) {
    selectedProp = !selectedProp;
    const newSelected = selectedProp
      ? this.selectedGroups.concat([item])
      : this.removeItem(item);
    this.$emit('selectedChanged', newSelected);
  }

  removeItem(item) {
    const index = this.selectedGroups.indexOf(item);
    return this.selectedGroups
      .slice(0, index)
      .concat(this.selectedGroups.slice(index + 1));
  }

  toggleAll() {
    const newSelected =
      this.selectedGroups.length === this.items.length
        ? []
        : this.items.slice();
    this.$emit('selectedChanged', newSelected);
  }
}
